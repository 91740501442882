import { cloneDeep } from "lodash";
import { DATA_TYPES_ENUM, FILTER_OPERATOR_TYPE, FILTER_OPERATOR_TYPE_ENUM } from "../constants";
import { KeyValuePair, OptionType } from "../constants/type";
import { isEmptyOrNull } from "./string";
import { objectToArray } from "./object";

export const ToOptionTypeList = (list: { [key: number]: string }): OptionType[] => {
    return Object.keys(list)
        .map(x => parseInt(x))
        .map<OptionType>(x => ({ text: list[x], value: x }));
};

export const ToOptionTypeList2 = (list: { [key: string]: string }): OptionType[] => {
    return Object.keys(list).map<OptionType>(x => ({ text: x, value: list[x] }));
};

export const ToOptionTypeList3 = (list: KeyValuePair[]): OptionType[] => {
    return list.map<OptionType>((x: KeyValuePair) => x as OptionType);
};

export const GetOperatorList = (type: number) => {
    switch (type) {
        case DATA_TYPES_ENUM.Boolean:
            return ToOptionTypeList(FILTER_OPERATOR_TYPE).filter(x => x.value.toString() === FILTER_OPERATOR_TYPE_ENUM.TrueFalse.toString());
        default:
            return ToOptionTypeList(FILTER_OPERATOR_TYPE).filter(x => x.value.toString() !== FILTER_OPERATOR_TYPE_ENUM.TrueFalse.toString());
    }
};

export const ToObjectWithKey = (list: any[], key: string = "", singleKey: string = "", fillDefault: any = undefined) => {
    return isEmptyOrNull(key)
        ? list.reduce((obj: any, x: any) => {
              obj[x] = fillDefault ? fillDefault : x;
              return obj;
          }, {})
        : list.reduce((obj: any, x: any) => {
              obj[x[key]] =
                  singleKey.length > 0
                      ? fillDefault
                          ? { ...(fillDefault && fillDefault), ...{ [singleKey]: x[singleKey] } }
                          : x[singleKey]
                      : fillDefault
                      ? fillDefault
                      : x;
              return obj;
          }, {});
};

export const GetObjectByFilterProps = (list: any[], key: string, target: string[] | string | number, exceptTarget: boolean = false) => {
    if (Array.isArray(target)) {
        let tg = target as string[];
        if (exceptTarget) {
            return list.filter(x => x?.hasOwnProperty(key) && !tg.includes(x[key]));
        } else {
            return list.filter(x => x?.hasOwnProperty(key) && tg.includes(x[key]));
        }
    }
    return list.filter(x => x?.hasOwnProperty(key) && x[key] === `${target}`);
};

export const GetUniqueKeysList = (list: any[], key: string = "", sorting: boolean = false, sortingExpression: any = undefined) => {
    let tmp = Object.keys(ToObjectWithKey(list, key));
    if (sorting) {
        if (sortingExpression) {
            tmp.sort(sortingExpression);
        } else {
            tmp.sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()));
        }
    }
    return tmp;
};

export const GetUniqueListByKey = (list: any[], key: string, sorting: boolean = false, sortingExpression: any = undefined) => {
    let tmp = objectToArray(ToObjectWithKey(list, key));
    if (sorting) {
        if (sortingExpression) {
            tmp.sort(sortingExpression);
        } else {
            tmp.sort((a: any, b: any) => `${a[key]}`.toLowerCase().localeCompare(`${b[key]}`.toLowerCase()));
        }
    }
    return tmp;
};

export const GetObjectSumByKey = (list: any[], key: string, countKey: string, toArray: boolean = false) => {
    let finalObject = cloneDeep(list).reduce((final: any, item: any) => {
        if (Object.keys(final).indexOf(item[key]) > -1) {
            final[item[key]] += item[countKey];
        } else {
            final[item[key]] = item[countKey];
        }
        return final;
    }, {});

    return toArray
        ? Object.keys(finalObject).reduce((finalList: any, item: string) => {
              let tmp: any = {};
              tmp[key] = item;
              tmp[countKey] = finalObject[item];
              finalList.push(tmp);
              return finalList;
          }, [])
        : finalObject;
};

export const GetObjectCountByKey = (list: any[], key: string, countKey: string = "", toArray: boolean = false) => {
    let finalObject = cloneDeep(list).reduce((final: any, item: any) => {
        if (final?.hasOwnProperty(item[key])) {
            final[item[key]] += 1;
        } else {
            final[item[key]] = 1;
        }
        return final;
    }, {});

    return toArray
        ? Object.keys(finalObject).reduce((finalList: any, item: string) => {
              let tmp: any = {};
              tmp[key] = item;
              tmp[countKey] = finalObject[item];
              finalList.push(tmp);
              return finalList;
          }, [])
        : finalObject;
};

export const SortList = (list: any[], key: string = "", sortingExpression: any = undefined, direction: ARRAY_SORT_DIRECTION = "ASC") => {
    if (sortingExpression) {
        list.sort(sortingExpression);
    } else if (!isEmptyOrNull(key)) {
        if (direction === "ASC") {
            list.sort((a: any, b: any) => `${a[key]}`.toLowerCase().localeCompare(`${b[key]}`.toLowerCase()));
        } else {
            list.sort((a: any, b: any) => `${b[key]}`.toLowerCase().localeCompare(`${a[key]}`.toLowerCase()));
        }
    }
    return list;
};

export const StringFormat = (format: string, ...args: any[]) => {
    let return_string = format;
    for (let index = 0; index < args.length; index++) {
        return_string = return_string.replace("{" + index + "}", args[index].toString());
    }
    return return_string;
};

export const addOrRemoveFromArray = (item: any[], arr: any[], key: string | undefined = undefined) => {
    let sIS = [...arr];
    if (key) {
        item.forEach((x: any) => {
            let idx: number = sIS.findIndex((y: any) => y[key] === x[key]);
            if (idx > -1) {
                sIS.splice(idx, 1);
            } else {
                sIS.push(x);
            }
        });
    } else {
        item.forEach((x: any) => {
            let idx: number = sIS.findIndex((y: any) => y === x);
            if (idx > -1) {
                sIS.splice(idx, 1);
            } else {
                sIS.push(x);
            }
        });
    }
    return sIS;
};

export const ARRAY_ACTION_TYPE = {
    INNER: "INNER",
    OUTER: "OUTER",
    LEFT_OUTER: "LEFT_OUTER",
    RIGHT_OUTER: "RIGHT_OUTER",
    UNION_DISTINCT: "UNION_DISTINCT",
};

export type ARRAY_SORT_DIRECTION = "ASC" | "DESC";

export const GetConstraintKeyList = (currentList: any[], targetList: any[], type: string): any[] => {
    switch (type) {
        case ARRAY_ACTION_TYPE.INNER: {
            let strList: string[] = targetList.map(x => `${x}`);
            return currentList.filter(x => strList.includes(`${x}`));
        }
        case ARRAY_ACTION_TYPE.OUTER: {
            let currStrList: string[] = currentList.map(x => `${x}`);
            let tarStrList: string[] = targetList.map(x => `${x}`);
            return tarStrList.reduce(
                (final: any[], x: any) => {
                    if (!currStrList.includes(`${x}`)) {
                        final.push(x);
                    }
                    return final;
                },
                currStrList.reduce((final: any[], x: any) => {
                    if (!tarStrList.includes(`${x}`)) {
                        final.push(x);
                    }
                    return final;
                }, [])
            );
        }
        case ARRAY_ACTION_TYPE.LEFT_OUTER: {
            let strList: string[] = targetList.map(x => `${x}`);
            return currentList.filter(x => !strList.includes(`${x}`));
        }
        case ARRAY_ACTION_TYPE.RIGHT_OUTER: {
            let strList: string[] = currentList.map(x => `${x}`);
            return targetList.filter(x => !strList.includes(`${x}`));
        }
        case ARRAY_ACTION_TYPE.UNION_DISTINCT:
            let tmpObj: any = currentList.reduce((finalObj: any, x: string) => {
                finalObj[x] = 1;
                return finalObj;
            }, {});
            tmpObj = targetList.reduce((finalObj: any, x: string) => {
                finalObj[x] = 1;
                return finalObj;
            }, tmpObj);
            return Object.keys(tmpObj);
        default:
            return [];
    }
};

export const replaceDomainNameToZero = (obj: any, urlPlaceholder: string) => {
    if (typeof obj === "object") {
        if ("raw" in obj && typeof obj["raw"] === "string") {
            obj["raw"] = obj["raw"].replace(new RegExp(urlPlaceholder, "g"), "{0}");
        }

        for (const key in obj) {
            obj[key] = replaceDomainNameToZero(obj[key], urlPlaceholder);
        }
    }

    return obj;
};

export const replaceZeroToDomainName = (obj: any, placeholder: string, replacement: string) => {
    if (typeof obj === "object") {
        if ("raw" in obj && typeof obj["raw"] === "string") {
            obj["raw"] = obj["raw"].split(placeholder).join(replacement);
        }

        for (const key in obj) {
            obj[key] = replaceZeroToDomainName(obj[key], placeholder, replacement);
        }
    }

    return obj;
};

export const rangeArrayInit = (totalLength: number, startIndex: number = 0) => Array.from({ length: totalLength }, (_, index) => startIndex + index);

export const findLabelBasedOnValue = (list: KeyValuePair[], id: number | string) => {
    return list.find(x => x.value === `${id}`)?.text;
};

export const filterAllColsClientTable = (data: any, searchValue: string) => {
    if (!searchValue) return data;
    const searchArr = `${searchValue}`.split(" ");
    return data.filter((o: any) => searchArr.every(searchVal => Object.keys(o).some(k => `${o[k]}`.toLowerCase().includes(searchVal.toLowerCase()))));
};
