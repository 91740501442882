import FlexiDataTable from "@/components/FlexiDataTable";
import { ComponentType, CALLBACK_KEY, SUCCESS_FAILED, InnerPageActionMode } from "@/constants";
import { FlexiDataTableOptionsProps, FlexiDataTableCallbackProps, CreateEditInlineFormBased } from "@/constants/type";
import AuthHelper, { AuthKeys } from "@/helpers/authHelper";
import { apiRequest } from "@/services/apiConfig";
import { APIs } from "@/services/apis";
import { DTColProps, DataTableColumnRender, ErrorMessageHandler, ErrorCatchValidator } from "@/utils/Common";
import { useState, useEffect } from "react";
import SymbolAssetTypeCreateEditPage from "./SymbolAssetTypeCreateEditPage";

export interface SymbolAssetTypeListProps {}

interface SymbolAssetType {
    symbolAssetTypeId: number;
    symbolAssetType: string;
    createdBy: string;
    createdDateUtc: string;
    updateBy: string | number | null;
    updateTimeUtc: string | null;
}

const SymbolAssetTypeList = (props: SymbolAssetTypeListProps) => {
    const [isFirstOpt, setIsFirstOpt] = useState<boolean>(true);
    const [formObject, setFormObject] = useState<CreateEditInlineFormBased>({ mode: InnerPageActionMode.CREATE_NEW });
    const [refetch, setRefetch] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<SymbolAssetType[]>([]);

    const authHp = new AuthHelper();
    const enableUpdate = authHp.isAuthorized(AuthKeys.CENTRALIZED_SETTINGS_SYMBOL_ASSET_TYPE_MANAGEMENT_EDIT);

    const columns: any[] = [
        {
            title: "Symbol Asset Type",
            dataIndex: "symbolAssetType",
            key: "symbolAssetType",
            options: {
                filter: {
                    type: ComponentType.text,
                    value: "",
                },
            },
        },

        DTColProps.DateTime({
            width: "12.5vw",
            title: "Last Updated Time (Local)",
            dataIndex: "updateTimeUtc",
            key: "updateTimeUtc",
            render: (value: string, rowData: SymbolAssetType) =>
                DataTableColumnRender.DateTime(rowData.updateTimeUtc === null ? rowData.createdDateUtc : rowData.updateTimeUtc),
            sorter: (a: any, b: any) => (a.updateTimeUtc < b.updateTimeUtc ? -1 : 1),
        }),
    ];

    const options: FlexiDataTableOptionsProps = {
        separateActionButton: true,
        add: enableUpdate,
        edit: enableUpdate,
        delete: enableUpdate,
    };

    const componentCallback: FlexiDataTableCallbackProps = (type, assetType) => {
        switch (type) {
            case CALLBACK_KEY.CREATE_NEW:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.CREATE_NEW, id: undefined });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_EDIT:
                setIsFirstOpt(prev => !prev);
                setFormObject({ mode: InnerPageActionMode.EDIT, id: assetType.symbolAssetTypeId });
                setRefetch(prev => prev + 1);
                break;
            case CALLBACK_KEY.DO_DELETE:
                apiRequest(APIs.DELETE_SYMBOL_ASSET_TYPE, {
                    symbolAssetTypeId: assetType.symbolAssetTypeId,
                })
                    .then(data => {
                        ErrorMessageHandler(assetType.symbolAssetType, SUCCESS_FAILED.SUCCESS_DELETE_DATA);
                        getListing();
                    })
                    .catch(error =>
                        ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_DELETE_DATA, err))
                    );
                break;
            default:
                break;
        }
    };

    const getListing = () => {
        setIsLoading(true);
        apiRequest(APIs.GET_SYMBOL_ASSET_TYPE_LISTING, {})
            .then((res: SymbolAssetType[]) => {
                setData(res);
            })
            .catch(error => {
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("symbol asset type", SUCCESS_FAILED.FAILED_UPDATE_DATA, err));
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        getListing();
        return () => {};
    }, []);

    return (
        <>
            <div className="settings-panel-main-title-container">
                <div className="title">
                    <span>Symbol Asset Type Management</span>
                </div>
            </div>
            <div className="single-page">
                <div className={`${isFirstOpt ? "active" : ""}`}>
                    <FlexiDataTable
                        bordered
                        rowKeyProperty="symbolAssetTypeId"
                        title=""
                        columns={columns}
                        options={options}
                        dataSource={data}
                        callback={componentCallback}
                        loading={isLoading}
                    />
                </div>
                <div className={`${isFirstOpt ? "" : "active"}`}>
                    <SymbolAssetTypeCreateEditPage
                        {...formObject}
                        callback={(action: InnerPageActionMode, value: any) => {
                            switch (action) {
                                case InnerPageActionMode.BACK:
                                    setIsFirstOpt(prev => !prev);
                                    if (value?.hasOwnProperty("refreshMainList") && value["refreshMainList"]) {
                                        getListing();
                                    }
                                    break;
                            }
                        }}
                        resetState={refetch}
                    />
                </div>
            </div>
        </>
    );
};

export default SymbolAssetTypeList;
