import React, { useEffect, useMemo, useState } from "react";
import { useRCDataCenterTypes } from "../../../hooks/useRCDataCenter";
import type { IRCDataCenterData } from "../../../hooks/useRCDataCenter";
import useRCDataCenterStore from "../../../store/useRCDataCenterStore";
import { Badge, Col, Input, Row, Space, Switch, Table, Tag, Tooltip, Typography } from "antd";
import { FaRegCheckCircle } from "react-icons/fa";
import { colorMap } from "../StatusMonitor/statusUtils";
import { WarningOutlined } from "@ant-design/icons";
import { matchSorter } from "match-sorter";
import MessageCard from "../components/MessageCard";
import BasicFilter from "../../../components/BasicFilter";
import DownloadDataCenterButton from "./DownloadDataCenterButton";
import soundFile from "../../../assets/audios/rc/dc-monitor-alert.mp3";
import DownloadDataCenterHistoryButton from "./DownloadDataCenterHistoryButton";
import useRCDataCenter from "../../../hooks/useRCDataCenter";
import { isNumberValue } from "@/utils/Common";
import { useAudioPlayer } from "@/helpers/audioHelper";

const StatusWrap = ({ children }: { children: React.ReactNode }) => {
    return <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>{children}</div>;
};
const StatusHeaderWrap = ({ label, shorten = false, warningNumbers = 0 }: { label: string; shorten?: boolean; warningNumbers?: number }) => {
    const final = shorten ? label.slice(0, 3) : label;
    const moreThanThree = label.length > 3;
    const { Text } = Typography;
    return (
        <Tooltip title={label} placement="bottom">
            <Badge style={{ padding: 0 }} count={warningNumbers}>
                <Text style={{ color: warningNumbers >= 1 ? colorMap.error : colorMap.success, display: "block", paddingRight: "0.25rem" }}>
                    {final}
                    {shorten && moreThanThree && "."}
                </Text>
            </Badge>
        </Tooltip>
    );
};

const DataCenterMonitorTable = () => {
    const { playSound } = useAudioPlayer(soundFile);
    const records = useRCDataCenterStore(state => state.records);
    const { rcDataCenterTypes } = useRCDataCenterTypes();
    const { rcDataCenter, isLoading, isFetching } = useRCDataCenter();
    const dataDataCenter: IRCDataCenterData[] = rcDataCenter?.data ?? [];
    const dataDataCenterTypes = rcDataCenterTypes ?? [];
    const { Title, Text } = Typography;
    const [showFullStatusName, setShowFullStatusName] = useState<boolean>(false);
    const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
    const statusWidth = showFullStatusName ? 100 : 80;

    const [searchQuery, setSearchQuery] = useState("");
    // console.log(rcIssuesLogNoMoneySettings);

    const result = useMemo(() => {
        if (dataDataCenter?.length >= 1) {
            let sortedObj: any = dataDataCenter.reduce(
                (finalObj: any, curr: IRCDataCenterData) => {
                    if (
                        (isNumberValue(curr.lastUpdateWarning) && curr.lastUpdateWarning > 0) ||
                        (isNumberValue(curr.loginError) && curr.loginError === 2)
                    ) {
                        finalObj.err.push(curr);
                    } else {
                        finalObj.warn.push(curr);
                    }
                    return finalObj;
                },
                { err: [], warn: [] }
            );

            return [
                ...sortedObj.err.sort((a: IRCDataCenterData, b: IRCDataCenterData) =>
                    (a.loginError << 1) + a.lastUpdateWarning - ((b.loginError << 1) + b.lastUpdateWarning) > 0 ? -1 : 1
                ),
                ...sortedObj.warn,
            ];

            return matchSorter(dataDataCenter ?? [], searchQuery ?? "", {
                keys: ["serverId", "dcName", "dcUrl"],
            });
        }
        return [];
    }, [dataDataCenter, searchQuery]);

    // get total login error, cpu warning, free memory warning, free disk warning, nic warning, ping time, in object style
    // final object will be like {loginError: 0, cpuWarning: 0, freeMemoryWarning: 0, freeDiskWarning: 0, nicWarning: 0, pingTime: 0}
    const getTotalStatus = dataDataCenter?.reduce(
        (acc, curr) => {
            return {
                loginError: acc.loginError + (curr.loginError > 1 ? 1 : 0),
                cpuWarning: acc.cpuWarning + (curr.cpuWarning > 0 ? 1 : 0),
                freeMemoryWarning: acc.freeMemoryWarning + (curr.freeMemoryWarning > 0 ? 1 : 0),
                freeDiskWarning: acc.freeDiskWarning + (curr.freeDiskWarning > 0 ? 1 : 0),
                nicWarning: acc.nicWarning + (curr.nicWarning > 0 ? 1 : 0),
                pingTime: acc.pingTime + (curr.pingTime < 0 ? 1 : 0),
            };
        },
        { loginError: 0, cpuWarning: 0, freeMemoryWarning: 0, freeDiskWarning: 0, nicWarning: 0, pingTime: 0 }
    );
    // console.log(getTotalStatus);

    function getNamesFromValue(value: number): string[] {
        if (value === 0) return ["General"];
        return (
            [...dataDataCenterTypes]
                // return [{ type: 0, name: "General", order: null }, ...dataDataCenterTypes]
                ?.filter(item => (value & item.type) === item.type)
                .map(item => item.name)
        );
    }

    const columns = [
        {
            title: "Server",
            dataIndex: "serverId",
            key: "serverId",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.serverId.localeCompare(b.serverId),
        },
        {
            title: "Data Center",
            dataIndex: "dcName",
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text style={{ lineHeight: 1 }} strong>
                            {text}
                        </Text>
                        <Text style={{ lineHeight: 0.8 }} type="secondary">
                            <small>{record.dcUrl}</small>
                        </Text>
                    </div>
                );
            },
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.dcName.localeCompare(b.dcName),
        },
        {
            title: "Server Type",
            dataIndex: "typeFlag",
            key: "serverType",

            render: (text: number, record: IRCDataCenterData) => {
                const type = getNamesFromValue(text);
                return type.map((item, index) => {
                    return <Tag key={index}>{item}</Tag>;
                });
            },
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.typeFlag - b.typeFlag,
        },
        {
            title: <StatusHeaderWrap label="Login" shorten={!showFullStatusName} warningNumbers={getTotalStatus.loginError} />,
            width: statusWidth,
            dataIndex: "loginError",
            key: "loginError",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.loginError - b.loginError,
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <StatusWrap>
                        {record.loginError > 1 ? (
                            <WarningOutlined style={{ color: colorMap.error }} />
                        ) : (
                            <FaRegCheckCircle style={{ color: colorMap.success, opacity: 0.5 }} />
                        )}
                    </StatusWrap>
                );
            },
        },
        {
            title: <StatusHeaderWrap label="CPU" shorten={!showFullStatusName} warningNumbers={getTotalStatus.cpuWarning} />,
            width: statusWidth,
            dataIndex: "cpuWarning",
            key: "cpuWarning",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.cpuWarning - b.cpuWarning,
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <StatusWrap>
                        {record.cpuWarning > 0 ? (
                            <WarningOutlined style={{ color: colorMap.error }} />
                        ) : (
                            <FaRegCheckCircle style={{ color: colorMap.success, opacity: 0.5 }} />
                        )}
                    </StatusWrap>
                );
            },
        },
        {
            title: <StatusHeaderWrap label="Memory" shorten={!showFullStatusName} warningNumbers={getTotalStatus.freeMemoryWarning} />,
            width: statusWidth,
            dataIndex: "freeMemoryWarning",
            key: "freeMemoryWarning",

            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.freeMemoryWarning - b.freeMemoryWarning,
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <StatusWrap>
                        {record.freeMemoryWarning > 0 ? (
                            <WarningOutlined style={{ color: colorMap.error }} />
                        ) : (
                            <FaRegCheckCircle style={{ color: colorMap.success, opacity: 0.5 }} />
                        )}
                    </StatusWrap>
                );
            },
        },
        {
            title: <StatusHeaderWrap label="Disk" shorten={!showFullStatusName} warningNumbers={getTotalStatus.freeDiskWarning} />,
            width: statusWidth,
            dataIndex: "freeDiskWarning",
            key: "freeDiskWarning",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.freeDiskWarning - b.freeDiskWarning,
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <StatusWrap>
                        {record.freeDiskWarning > 0 ? (
                            <WarningOutlined style={{ color: colorMap.error }} />
                        ) : (
                            <FaRegCheckCircle style={{ color: colorMap.success, opacity: 0.5 }} />
                        )}
                    </StatusWrap>
                );
            },
        },
        {
            title: <StatusHeaderWrap label="NIC" shorten={!showFullStatusName} warningNumbers={getTotalStatus.nicWarning} />,
            width: statusWidth,
            dataIndex: "nicWarning",
            key: "nicWarning",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.nicWarning - b.nicWarning,
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <StatusWrap>
                        {record.nicWarning > 0 ? (
                            <WarningOutlined style={{ color: colorMap.error }} />
                        ) : (
                            <FaRegCheckCircle style={{ color: colorMap.success, opacity: 0.5 }} />
                        )}
                    </StatusWrap>
                );
            },
        },
        {
            title: <StatusHeaderWrap label="Ping" shorten={!showFullStatusName} warningNumbers={getTotalStatus.pingTime} />,
            width: statusWidth,
            dataIndex: "pingTime",
            key: "pingTime",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.pingTime - b.pingTime,
            render: (text: string, record: IRCDataCenterData) => {
                return (
                    <StatusWrap>
                        {record.pingTime < 0 ? (
                            <WarningOutlined style={{ color: colorMap.error }} />
                        ) : (
                            <FaRegCheckCircle style={{ color: colorMap.success, opacity: 0.5 }} />
                        )}
                    </StatusWrap>
                );
            },
        },
        {
            title: "Last Updated Date Time",
            dataIndex: "lastUpdate",
            key: "lastUpdated",
            sorter: (a: IRCDataCenterData, b: IRCDataCenterData) => a.lastUpdate.localeCompare(b.lastUpdate),
        },
    ].filter((item: any) => !filteredColumns.includes(item.dataIndex));

    useEffect(() => {
        if (!isFetching) {
            if (dataDataCenter.length >= 1) {
                playSound();
            };
        }
    }, [isFetching]);

    return (
        <>
            <BasicFilter
                titleBarChildren={
                    <Space>
                        <DownloadDataCenterButton />
                        <DownloadDataCenterHistoryButton />
                    </Space>
                }
            >
                <Row gutter={[8, 8]}>
                    <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Space style={{ marginLeft: "auto" }}>
                            <Switch
                                size="small"
                                defaultChecked={!showFullStatusName}
                                onChange={(checked: boolean) => {
                                    setShowFullStatusName(!checked);
                                }}
                            />
                            <Text>Shorten Status Name</Text>
                        </Space>
                    </Col>
                    <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Space style={{ marginLeft: "auto" }}>
                            <Switch
                                size="small"
                                defaultChecked={!filteredColumns.includes("typeFlag")}
                                onChange={(checked: boolean) => {
                                    setFilteredColumns(prev => {
                                        if (checked) {
                                            return prev.filter(item => item !== "typeFlag");
                                        }
                                        return [...prev, "typeFlag"];
                                    });
                                }}
                            />
                            <Text>Show Server Type</Text>
                        </Space>
                    </Col>
                    <Col span={8} xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
                        <Space style={{ marginLeft: "auto" }}>
                            <Switch
                                size="small"
                                defaultChecked={!filteredColumns.includes("lastUpdate")}
                                onChange={(checked: boolean) => {
                                    setFilteredColumns(prev => {
                                        if (checked) {
                                            return prev.filter(item => item !== "lastUpdate");
                                        }
                                        return [...prev, "lastUpdate"];
                                    });
                                }}
                            />
                            <Text>Show Last Updated Date Time</Text>
                        </Space>
                    </Col>
                </Row>
            </BasicFilter>
            <div style={{ padding: "0 1rem", marginTop: "1rem" }}>
                <Space direction="vertical">
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "4px", alignItems: "center" }}>
                        <Title level={4}>Data Center Status</Title>
                    </div>
                    {isLoading ? (
                        <MessageCard type="info">Loading...</MessageCard>
                    ) : (
                        <>
                            {dataDataCenter.length === 0 ? (
                                <MessageCard type="success">All good</MessageCard>
                            ) : (
                                <MessageCard type="error">
                                    {dataDataCenter.length} server{dataDataCenter.length > 1 ? "s" : ""} warning
                                    <br />
                                </MessageCard>
                            )}
                            <Space wrap>
                                {Object.keys(getTotalStatus)?.map((key, index) => {
                                    const mapKeyToName = (passKey: string) => {
                                        switch (passKey) {
                                            case "loginError":
                                                return "Login Error";
                                            case "cpuWarning":
                                                return "CPU Warning";
                                            case "freeMemoryWarning":
                                                return "Free Memory Warning";
                                            case "freeDiskWarning":
                                                return "Free Disk Warning";
                                            case "nicWarning":
                                                return "NIC Warning";
                                            case "pingTime":
                                                return "Ping Warning";
                                            default:
                                                return null;
                                        }
                                    };
                                    // @ts-ignore
                                    if (getTotalStatus[key] === 0) return null;
                                    return (
                                        <MessageCard type="warning" key={key}>
                                            {/* @ts-ignore */}
                                            {getTotalStatus[key]} {mapKeyToName(key)}
                                        </MessageCard>
                                    );
                                })}
                            </Space>
                            <Input
                                placeholder="Search Server, Data Center"
                                onChange={e => {
                                    setSearchQuery(e.target.value);
                                }}
                            />

                            <Table
                                columns={columns}
                                dataSource={result}
                                rowKey={(record: IRCDataCenterData) => record.serverId + record.dcName}
                                size="small"
                                pagination={false}
                                sticky
                            />
                        </>
                    )}
                </Space>
            </div>
        </>
    );
};

export default DataCenterMonitorTable;
