import React, { useCallback, useEffect, useMemo, useState } from "react";
import FlexiDataTable from "../../../../../components/FlexiDataTable";
import { FlexiDataTableCallbackProps, FlexiDataTableOptionsProps, KeyValuePair } from "../../../../../constants/type";
import { APIs } from "../../../../../services/apiConfig";
import { plainAxiosInstance } from "../../../../../services/axiosSetup";
import { CALLBACK_KEY, ComponentType, SUCCESS_FAILED } from "../../../../../constants";
import { DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "../../../../../utils/Common";
import { FileTextOutlined } from "@ant-design/icons";
import { Button, Form, Table, Tooltip } from "antd";
import moment from "moment";
import FilterFormComponent, { FilterFormComponentCallbackKey } from "./filterFormComponent";
import NoteEntitiesModal, { NoteEntitiesModalCallbackKey } from "./noteEntitiesModal";
import { defaultIfEmptyOrNull, isEmptyOrNull } from "../../../../../utils/string";
import AddNoteModal, { AddNoteModalCallbackKey } from "./addNoteModal";
import { ConfigDiffRecordDataProps, ConfigDiffRecTabItems, DropdownSelectionProps, NoteEntityProps2 } from "./type";
import { DefaultIfEmpty } from "@/utils/object";

export interface DataTableComponentProps {
    currentTabKey: string;
    currInitialSelected: React.ReactNode;
    resetFirstLoad: number;
    configDiffRecordData: ConfigDiffRecordDataProps[];
};

const ActionTypeProps = {
    0: "Create",
    1: "Update",
    2: "Delete",
    3: "Create",
};

const DataTableComponent = (props: DataTableComponentProps) => {
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [runRefetchDataList, setRunRefetchDataList] = useState<boolean>(false);
    const [isAddNoteModalVisible, setIsAddNoteModalVisible] = useState<boolean>(false);
    const [currNoteEntity, setCurrNoteEntity] = useState<NoteEntityProps2 | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [servers, setServers] = useState<KeyValuePair[]>([]);
    const [filterParams, setFilterParams] = useState<any>({
        startDate: moment().startOf("day").subtract(1, "day").format("YYYY-MM-DD"),
        endDate: moment().endOf("day").format("YYYY-MM-DD"),
    });
    const [selectedKey, setSelectedKey] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [filterForm] = Form.useForm();

    const [currentSelected, setCurrentSelected] = useState<string>(props.currInitialSelected?.toString() || "");

    const columnWrapper = (type: string, textAlign: string, values: any, className: string[] = []) => {
        switch (type) {
            case "xsm":
                return DTColProps.XSmall(values, [...[`text-${textAlign}`], ...className]);
            case "m":
                return DTColProps.Middle(values, [...[`text-${textAlign}`], ...className]);
            case "l":
                return DTColProps.Large(values, [...[`text-${textAlign}`], ...className]);
            case "xl":
                return DTColProps.XLarge(values, [...[`text-${textAlign}`], ...className]);
            case "sc":
                return DTColProps.SCurrency(values, className, -1);
            case "c":
                return DTColProps.Currency(values, className, -1);
            default:
                return DTColProps.Small({ ...values, width: "9vw" }, [...[`text-${textAlign}`], ...className]);
        }
    };

    const dropdownSelection: DropdownSelectionProps = useMemo(() => {
        let configObj = DefaultIfEmpty(props.configDiffRecordData, props.currentTabKey, {}),
            configProps = DefaultIfEmpty(configObj, "configDiffTabItems", []).find((item: ConfigDiffRecTabItems) => item.label === currentSelected);
        return {
            segmentOpts: Object.keys(props.configDiffRecordData),
            subOption: DefaultIfEmpty(configObj, "subOptions", []),
            KeyStr: DefaultIfEmpty(configProps, "configDiffType", ""),
            columns: DefaultIfEmpty(configProps, "configDiffProperties", []),
            selectedCols: DefaultIfEmpty(configProps, "colorMarkedColumnIds", []),
            excludeCompareColumns: DefaultIfEmpty(configProps, "ignoreColumnIds", []),
            dbName: DefaultIfEmpty(configProps, "dbName", ""),
            pkCols: DefaultIfEmpty(configProps, "pkCols", []),
            configType: DefaultIfEmpty(configProps, "configType", "")
        };
    }, [props.currentTabKey, currentSelected, props.configDiffRecordData]);

    const columns = useMemo(
        () => [
            ...[
                // DTColProps.Middle({
                //     title: "More",
                //     dataIndex: "noteEntities",
                //     key: "noteEntities",
                //     // render: (text: NoteEntitiesProps[] | null) => defaultIfEmptyOrNull(text, [{ note: "" }])[0].note,
                //     options: {
                //         filter: {
                //             type: ComponentType.switch,
                //             value: ["Show All", "Empty Note Only"],
                //             callback: (filterValue: any, rowData: any) =>
                //                 filterValue ? defaultIfEmptyOrNull(rowData.noteEntities, []).length === 0 : true,
                //         },
                //         visible: false,
                //     },
                // }),
                DTColProps.Small({
                    title: "Config Date",
                    dataIndex: "ConfigDate",
                    key: "ConfigDate",
                    sorter: (a: any, b: any) => moment(a.ConfigDate).diff(moment(b.ConfigDate)),
                    options: {
                        filter: {
                            type: ComponentType.date,
                            value: [],
                            dateFormat: "YYYY-MM-DD",
                            callback: (filterValue: any, rowData: any) => {
                                if (filterValue === "") return true;
                                else {
                                    const _filterValue = moment(filterValue as moment.Moment).startOf("day").format("YYYY-MM-DD");
                                    const thisConfigDate = moment(rowData["ConfigDate"]).format("YYYY-MM-DD");
                                    return _filterValue === thisConfigDate;
                                };
                            },
                        },
                    },
                }),
                {
                    title: "Search All",
                    dataIndex: "searchAll",
                    key: "searchAll",
                    options: {
                        filter: {
                            type: ComponentType.text,
                            value: "",
                            callback: (filterValue: string, rowData: any) => {
                                let findText = filterValue.toLowerCase();
                                return Object.values(rowData).some((value: any) => `${value}`.toLowerCase().indexOf(findText) > -1);
                            },
                        },
                        visible: false,
                    },
                },
            ],
            ...dropdownSelection.columns.map((x: string) => {
                let colTxtArr = x.split("||"),
                    colTxt = colTxtArr[0],
                    colType = colTxtArr[1],
                    colAlign = colTxtArr.length > 2 ? colTxtArr[2] : "left";

                return columnWrapper(
                    colType,
                    colAlign,
                    {
                        title: colTxt,
                        dataIndex: `${colTxt}`,
                        key: `${colTxt}`,
                        render: (text: any) => `${defaultIfEmptyOrNull(text, "")}`,
                    },
                    // ["compare-color"]
                );
            }),
            ...[
                DTColProps.XXSmall(
                    {
                        title: "",
                        dataIndex: "actions",
                        key: "actions",
                        fixed: "right",
                        render: (_: any, rowData: any) => (
                            <Tooltip title="View Note List" placement="top" key={`vnl-${rowData.newKey}`}>
                                <Button
                                    type="text"
                                    icon={<FileTextOutlined />}
                                    onClick={() =>
                                        componentCallback(CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK, { key: "moredetails", data: rowData })
                                    }
                                />
                            </Tooltip>
                        ),
                    },
                    ["text-center"]
                ),
            ],
        ],
        [dropdownSelection.columns]
    );

    const options: FlexiDataTableOptionsProps = {
        defaultCollapseFilterPanel: true,
        separateActionButton: true,
        enableRowSelection: true,
        hideRowSelectionsSummary: true,
        rowSelectionData: {
            rowSelectionType: "checkbox",
            selectedRowKeys: selectedKey,
            options: {
                fixed: true,
                selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
                preserveSelectedRowKeys: true,
                disabled: data.length === 0 || isLoading,
            },
        },
        ...(selectedKey.length > 0 && {
            extraButtons: [{ text: "Add Notes", value: "addnotes", type: "primary" }],
        }),
    };

    const componentCallback: FlexiDataTableCallbackProps = (type: number, FormData: any) => {
        switch (type) {
            case CALLBACK_KEY.ROW_SELECTION_CALLBACK:
                let pkVals = FormData.selectedRows.map((x: any) => {
                    let tmp: any = {};
                    dropdownSelection.pkCols.forEach((currPkCol: string) => {
                        if (x[currPkCol] !== undefined) {
                            tmp = {
                                ...tmp,
                                [currPkCol]: x[currPkCol],
                            };
                        };
                    });
                    return { ConfigDate: x.ConfigDate, ...tmp };
                });
                setSelectedRows(pkVals);
                setSelectedKey(FormData.selectedRowKeys);
                break;
            case CALLBACK_KEY.OTHERS:
                if (FormData === "addnotes") {
                    setIsAddNoteModalVisible(true);
                };
                break;
            case CALLBACK_KEY.CUSTOM_ROW_OPTION_CALLBACK:
                if (FormData.key === "moredetails") {
                    let pkVals: any = {};
                    Object.keys(FormData.data)
                        .forEach((currCol: string) => {
                            if (dropdownSelection.pkCols.find((currPkCol: string) => currPkCol === currCol) !== undefined) {
                                pkVals = {
                                    ...pkVals,
                                    [currCol]: FormData.data[currCol],
                                }
                            }
                        });
                    setCurrNoteEntity({
                        dbName: dropdownSelection.dbName,
                        tbName: currentSelected,
                        pkCols: dropdownSelection.pkCols,
                        ConfigDate: FormData.data.ConfigDate,
                        pkVals,
                    });
                }
                break;
            default:
                break;
        }
    };

    const submitReason = useCallback(
        (reason: any) => {
            setIsSubmitting(true);
            plainAxiosInstance
                .put(`${APIs.RISK_TOOL.POST_LATEST_CONFIG_DIFF_RECORD_NOTES}?dbName=${encodeURIComponent(dropdownSelection.dbName)}&tbName=${encodeURIComponent(currentSelected)}`, reason)
                .then((res: any) => {
                    setTimeout(() => {
                        setSelectedKey([]);
                        setSelectedRows([]);
                        setIsAddNoteModalVisible(false);
                        ErrorMessageHandler("Reason created successfully", SUCCESS_FAILED.OTHERS_SUCCESS);
                        setRunRefetchDataList(true);
                        setIsSubmitting(false);
                    }, 300);
                })
                .catch((error: any) =>
                    ErrorCatchValidator(error, (err: any) => {
                        ErrorMessageHandler(`Error occurred during create reason`, SUCCESS_FAILED.OTHERS_FAILED, err);
                        setIsSubmitting(false);
                    })
                );
        },
        [dropdownSelection.dbName, currentSelected]
    );

    const getDataList = useCallback((offset: number, totalRecords: number, reqQuery: string) => {
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_CONFIG_DIFF_RECORD_DATA_LIST}${reqQuery}&limit=${500}&offset=${offset}`)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    // setData(prev => [...prev, ...res.data]);
                    let mergedData: any[] = [];
                    res.data.forEach(({ configDate, preConfigDate, noteEntities, ...rest }: { configDate: string, preConfigDate: string, noteEntities: any, [key: string]: any }) => {
                        // The rest data property keys.
                        const restKeys = Object.keys(rest);
                        // Merge current part data.
                        const currentData: any = {
                            // Assign ConfigDate and PreConfigDate.
                            ConfigDate: configDate, PreConfigDate: preConfigDate,
                            // The property for display edit note and save to DB.
                            noteEntity: ({ configDate: configDate, note: "", validator: "", ts: "" })
                        };
                        restKeys.forEach((key) => {
                            if (key.startsWith("previous"))
                                return;
                            if (key.startsWith("current"))
                                currentData[`${key.replaceAll("current", "")}`] = rest[`${key}`];
                            else
                                currentData[`${key.charAt(0).toUpperCase() + key.slice(1)}`] = rest[`${key}`];
                        });

                        // Merge previous part data.
                        let previousData: any = {};
                        if (preConfigDate !== undefined && configDate !== "1970-01-01") {
                            mergedData.push(currentData);
                            previousData = {
                                // Assign ConfigDate and PreConfigDate.
                                ConfigDate: preConfigDate, PreConfigDate: "1970-01-01",
                                // The property for display edit note and save to DB.
                                noteEntity: ({ configDate: configDate, note: "", validator: "", ts: "" })
                            };
                            restKeys.forEach((key) => {
                                if (key.startsWith("current"))
                                    return;
                                if (key.startsWith("previous"))
                                    previousData[`${key.replaceAll("previous", "")}`] = rest[`${key}`];
                                else
                                    previousData[`${key.charAt(0).toUpperCase() + key.slice(1)}`] = rest[`${key}`];
                            });
                            mergedData.push(previousData);
                        };
                        if (!previousData) {
                            currentData["Action"] = 3;
                            mergedData.push(currentData);
                        };
                    });

                    if (offset > 0) {
                        setData(prev => [...prev, ...mergedData.map((x: any, i: number) => ({ ...x, newKey: i }))]);
                    } else {
                        setData(mergedData.map((x: any, i: number) => ({ ...x, newKey: i })));
                    };
                };
                setSelectedKey([]);
                setSelectedRows([]);
            })
            .catch((error: any) => ErrorCatchValidator(error, (err: any) => console.log(err)))
            .finally(() => {
                if (totalRecords > offset + 500) {
                    getDataList(offset + 500, totalRecords, reqQuery);
                } else {
                    setIsLoading(false);
                }
            });
    }, []);

    const getDataListCount = useCallback(() => {
        let reqQuery = `?dbName=${encodeURIComponent(dropdownSelection.dbName)}&tbName=${encodeURIComponent(currentSelected)}&startDate=${encodeURIComponent(filterParams.startDate)}&endDate=${encodeURIComponent(filterParams.endDate)}`;
        plainAxiosInstance
            .get(`${APIs.RISK_TOOL.GET_LATEST_CONFIG_DIFF_RECORD_TOTAL}${!isEmptyOrNull(filterParams.server) ? reqQuery += `&server=${encodeURIComponent(filterParams.server)}` : reqQuery}`)
            .then((res: any) => {
                if (res.data > 0) {
                    setTimeout(() => getDataList(0, res.data, reqQuery), 300);
                } else {
                    setData([]);
                    setIsLoading(false);
                }
            })
            .catch((error: any) => {
                setData([]);
                setIsLoading(false);
                ErrorCatchValidator(error, (err: any) => ErrorMessageHandler("config diff data", SUCCESS_FAILED.FAILED_LOAD_DATA, err));
            })
            .finally(() => setIsFirstLoad(false));
    }, [filterParams, props, dropdownSelection, currentSelected]);

    const getConfig = () => {
        setIsLoading(true);
        plainAxiosInstance
            .get(APIs.RC_ROOT.GET_SERVERS)
            .then((res: any) => {
                if (res.data && res.data.length > 0) {
                    setServers(res.data.map((x: any) => ({ text: x.serverName, value: x.serverId })));
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setIsFirstLoad(true);
    }, [props.resetFirstLoad]);

    useEffect(() => {
        if (runRefetchDataList) {
            setIsLoading(true);
            getDataListCount();
            setRunRefetchDataList(false);
        }
    }, [runRefetchDataList]);

    useEffect(() => {
        getConfig();
        return () => { };
    }, []);

    return (
        <>
            <div className="table-component-container">
                <div className="search-panel">
                    <div className="labels">
                        <div>Please select the searching condition :</div>
                        <div className="right">Config Type :</div>
                    </div>
                    <div className="main-panel">
                        <FilterFormComponent
                            KeyStr={dropdownSelection.KeyStr}
                            currentTabKey={props.currentTabKey}
                            form={filterForm}
                            servers={servers}
                            callback={(type: number, data: any) => {
                                if (type === FilterFormComponentCallbackKey.FormSubmit) {
                                    setFilterParams(data);
                                    setRunRefetchDataList(true);
                                };
                                if (type === FilterFormComponentCallbackKey.DropdownSelection) {
                                    setCurrentSelected(data);
                                    setRunRefetchDataList(true);
                                };
                            }}
                            dbName={dropdownSelection.dbName}
                            tbName={currentSelected}
                            configType={dropdownSelection.configType}
                            initialFilterParams={filterParams}
                            dropdownSelections={dropdownSelection.subOption}
                            configDiffRecordData={props.configDiffRecordData}
                        />
                    </div>
                </div>
                <div className="table-panel">
                    {!isFirstLoad && (
                        <FlexiDataTable
                            bordered
                            rowKeyProperty="newKey"
                            title={
                                <>
                                    <span
                                        style={{ fontSize: "1rem", fontWeight: "bold", color: "#000000" }}
                                    >{`${currentSelected} Configuration Difference View`}</span>
                                    <span
                                        style={{ fontSize: "1rem", fontWeight: "bold", color: "#f00f00" }}
                                    >{` (** Odd row: current value, Even row: previous value)`}</span>
                                </>
                            }
                            columns={columns}
                            dataSource={data}
                            options={options}
                            callback={componentCallback}
                            loading={isLoading}
                            // filterInitialValue={{
                            //     noteEntities: false,
                            // }}
                            tableProps={{
                                rowClassName: (record: any, index: number) => {
                                    let actionClassName = "";
                                    switch (record.currentAction) {
                                        case 0:
                                            actionClassName = "row-add";
                                            break;
                                        case 1:
                                            actionClassName = "row-update";
                                            break;
                                        case 2:
                                            actionClassName = "row-delete";
                                            break;
                                        default:
                                            actionClassName = "row-create";
                                            break;
                                    }
                                    return actionClassName;
                                },
                            }}
                            pagination={{ defaultPageSize: 20 }}
                        />
                    )}
                </div>
            </div>
            <NoteEntitiesModal
                noteEntity={currNoteEntity}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case NoteEntitiesModalCallbackKey.Close:
                            setCurrNoteEntity(null);
                            break;
                        default:
                            break;
                    }
                }}
            />
            <AddNoteModal
                selectedKeys={selectedKey}
                isModalVisible={isAddNoteModalVisible}
                callback={(type: number, data: any) => {
                    switch (type) {
                        case AddNoteModalCallbackKey.FormSubmit:
                            submitReason(data);
                            break;
                        case AddNoteModalCallbackKey.Close:
                            setIsAddNoteModalVisible(false);
                            break;
                        default:
                            break;
                    }
                }}
                selectedRowPkVals={selectedRows}
                isSubmitting={isSubmitting}
            />
        </>
    );
};

export default DataTableComponent;
